export default [
    {
      path: "/",
      // name: "CommercialMainPage",
      component: () => import("@/components/commercial/CommercialMain.vue"),
      children: [
        {
          path: '',
          name: "CommercialMainHomePage",
          component: () => import("@/components/commercial/views/Home.vue"),
          meta: {
            disabledAppFooter: true,
          }
        },
      ]
    }
]