<template>
  <v-dialog
    v-model="show"
    transition="dialog-top-transition"
    @click:outside="closeDialog"
    width="460"
  >
    <v-card class="d-flex flex-column pa-5" outlined>
      <div style="position: absolute; top:0; right:0;">
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="d-flex justify-center">
        <v-sheet elevation="24" height="70" rounded width="70">
          <img
            :src="require('../assets/main-app.png')"
            height="64"
            rounded
            width="64"
            contain
          />
        </v-sheet>
      </div>

      <span class="text-h5 my-2 d-flex justify-center">
        Download Application on
      </span>

      <div class="d-flex flex-wrap justify-center">
        <a :href="appStoreUrl">
          <v-img
            width="200"
            :src="require('../assets/commercial/applestore.svg')"
            contain
          />
        </a>
        <a :href="googlePlayStoreUrl">
          <v-img
            width="200"
            :src="require('../assets/commercial/androidstore.svg')"
            contain
          />
        </a>
      </div>

      <!-- <v-card-actions class="justify-end">
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InstallAppDialog",
  data: () => ({
    appStoreUrl: "https://apps.apple.com/th/app/crypto-mation/id1600835933",
    googlePlayStoreUrl:
      "https://play.google.com/store/apps/details?id=com.aimationplus.cryptomation",
  }),
  methods: {
    closeDialog() {
      this.$store.dispatch("setDisplayInstallAppDialog");
    },
  },
  computed: {
    show: {
      get() {
        return this.$store.state.app.display_install_app_dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>
