export default [
  {
    path: "/subscriptions",
    name: "subscriptions",
    component: () => import("@/components/user/MySubscriptions.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import("@/components/user/ChangePassword.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/verifyemail",
    name: "verifyemail",
    component: () => import("@/components/user/VerifyEmail.vue"),
    meta: { requiresAuth: true }
  },
];

// /* webpackChunkName: "RobotDetail" */
