import * as types from "@/store/mutation-types";
import { getLocalStorage, setLocalStorage } from "@/services/localStorage";
import i18n from "@/plugins/i18n";

const langKey = "lang";

const getters = {
  display_install_app_dialog: (state) => state.display_install_app_dialog,
  app_language: (state) => state.app_language,
};

const actions = {
  setDisplayInstallAppDialog({ commit }) {
    commit(types.DISPLAY_INSTALL_APP_DIALOG);
  },
  setAppLanguage({ commit }, payload) {
    // set commit
    commit(types.SET_LANGUAGE, payload);
    // set to local stroange
    setLocalStorage(langKey, payload);
    // set to package
    i18n.locale = payload;
  },
};

const mutations = {
  [types.DISPLAY_INSTALL_APP_DIALOG](state) {
    state.display_install_app_dialog = !state.display_install_app_dialog;
  },
  [types.SET_LANGUAGE](state, payload) {
    state.app_language = payload;
  },
};

const state = {
  display_install_app_dialog: false,
  app_language: getLocalStorage(langKey) || 'en',
};

export default {
  state,
  getters,
  actions,
  mutations,
};
