<template>
  <v-app>
    <AppNavbar />
    <v-main>
      <router-view />
      <Footer />
    </v-main>

    <InstallAppDialog />
  </v-app>
</template>

<script>
import AppNavbar from "./components/AppNavbar";
import InstallAppDialog from "./components/InstallAppDialog.vue";
import Footer from "./components/Footer.vue";
import { syncUUID } from "@/services/api/system";
export default {
  name: "App",

  components: {
    AppNavbar,
    InstallAppDialog,
    Footer,
  },

  methods: {
    async appGetUUID() {
      await syncUUID();
    },
  },

  mounted() {
    this.appGetUUID();
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="sass">
.extra-padding
  padding-bottom: 96px !important
  padding-top: 96px !important

  @media screen and (max-width: 959px)
    padding-top: 48px !important
    padding-bottom: 48px !important
</style>
