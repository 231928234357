export default [
    {
      path: "/privacy",
      name: "privacy",
      component: () => import("@/components/policy/PrivacyPolicy.vue"),
    },
    {
      path: "/policy",
      name: "policy",
      component: () => import("@/components/policy/storePolicy.vue"),
    },
    {
      path: "/privacyandpolicy",
      name: "privacyandpolicy",
      component: () => import("@/components/policy/termOfServicePrivacyPolicy.vue"),
    },
    {
      path: "/termsofuse",
      name: "termsofuse",
      component: () => import("@/components/policy/termOfServicePrivacyPolicy.vue"),
    },
  ];
  
  // /* webpackChunkName: "RobotDetail" */
  