<template>
  <v-footer default padless v-if="showAppBar">
    <v-container>
      <v-row>
        <v-col>
          <v-card class="flex" flat tile>
            <v-card-text class="d-flex flex-row justify-space-between">
              <div>
                <v-btn
                  href="https://www.facebook.com/CryptoMationTH"
                  target="_blank"
                  class="mx-4 white--text"
                  icon
                >
                  <v-icon size="24px"> mdi-facebook </v-icon>
                </v-btn>

                <v-btn
                  href="https://twitter.com/crypto_mation"
                  target="_blank"
                  class="mx-4 white--text"
                  icon
                >
                  <v-icon size="24px"> mdi-twitter </v-icon>
                </v-btn>

                <v-btn
                  href="https://www.youtube.com/channel/UCUE5l-SC-LYoqxgHHLjDEAA"
                  target="_blank"
                  class="mx-4 white--text"
                  icon
                >
                  <v-icon size="24px"> mdi-youtube </v-icon>
                </v-btn>
              </div>

              <div>
                <a
                  class="white--text text-menu text-decoration-none"
                  :href="tempOfPrivacyLink"
                  > {{ $t('footer.termOfService')}}</a
                >
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="py-2 white--text text-center">
              Copyright © {{ new Date().getFullYear() }} —
              <strong
                ><a
                  class="white--text text-decoration-none"
                  href="https://crypto-mation.com/"
                  >Crypto-Mation</a
                ></strong
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    tempOfPrivacyLink: "https://crypto-mation.com/privacyandpolicy",
    icons: ["mdi-facebook"], //"mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    showAppBar: false,
  }),
  methods: {},
  mounted() {},
  watch: {
    $route(to) {
      if (to.meta.disabledAppFooter) {
        this.showAppBar = false;
      } else {
        this.showAppBar = true;
      }
    },
  },
};
</script>

<style scoped>
.text-menu {
  vertical-align: middle;
}
</style>
