import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);
// https://jareklipski.medium.com/changing-background-color-in-vuetify-js-and-nuxt-js-b4222cfa547e

export default new Vuetify({
  customVariables: ["~/assets/scss/variables.scss"],
  treeShake: true,
  theme: {
    dark: true,
    themes: {
      options: { customProperties: true },
      // dark: {
      //     primary: '#1C3146',

      // },
      // light: {
      //     primary: '#42A5F6',
      //     secondary: '#050B1F',
      //     accent: '#204165',
      // }
      light: {
        primary: "#42A5F6",
        secondary: "#050B1F",
        accent: "#204165",
      },
      dark: {
        primary: '#1C3146',
        secondary: "#0B1C3D",
        accent: "#204165",
      },

      // dark: {
      //     background: '#00a86b',
      //     primary: '#4caf50',
      //     secondary: '#ff8c00',
      //     accent: '#9c27b0'
      //     // primary: colors.blue.lighten3,//'#3739FF',
      //     // accent: '#101721',
      //     // secondary: colors.amber.darken3,
      //     // info: colors.teal.lighten1,
      //     // warning: colors.amber.base,
      //     // error: colors.deepOrange.accent4,
      //     // success: colors.green.accent3
      // }
    },
  },
});
