import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import routes from '@/router/routes'
import { store } from "@/store";

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...routes]
})


router.beforeEach((to, from, next) => {
    // checkForUpdates()
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

    const isSessionTokenSet = store.getters.isSessionTokenSet
    if (requiresAuth && !isSessionTokenSet) {
      return next('/')
    }
    // checkIfTokenNeedsRefresh()
    // store.commit(types.SUCCESS, null)
    // store.commit(types.ERROR, null)
    return next()
  })

export default router